<template>
  <div class="reportList">
    <ReportDetailComponent v-if="reportId != null" :reportId="reportId" :reportConfigId="configId" :reportName="reportName" :reportConfigName="configName"/>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ReportDetailComponent from '@/components/ReportDetailComponent.vue';

export default {
  name: 'ReportListView',
  props: [],
  components: {
    ReportDetailComponent,

  },
  data() {
    return {
      reportId: null,
      reportName: null,
    };
  },
  mounted() {
    this.configName = this.$route.params.reportConfigName;
    this.configId = this.$route.params.reportConfigId;
    this.reportName = this.$route.params.reportName;
    this.reportId = this.$route.params.reportId;
  },
};
</script>
