/* eslint-disable no-unused-vars,no-console,no-alert,no-underscore-dangle,no-bitwise */
const PDF = require('jspdf').jsPDF;
const Constants = require('./Constants');
const ReportHelper = require('./ReportHelper');
const CryptoHelper = require('./CryptoHelper');

const pageMargins = {
  left: 10, top: 17, right: 10, bottom: 10,
};
const fontSize = 9;
const defaultLineHeight = 6.2;
const defaultSpacing = 1;

const labelWidthPercent = 0.3;
const fieldPadding = 1;
const maxImageSize = 50;
const lineHeightFactor = 1.55;

const labelBackgroundColor = '#b8b8b8';
const valueBackgroundColor = '#d8d8d8';
const sectionTitleBackgroundColor = '#F49954';

const stateBackgroundColors = ['#B27C14', '#4363A3', '#34968F'];
const stateColor = '#FFFFFF';

const commentHeaderFontSize = 8;
const commentHeaderLineHeight = 5.5;

const locale = 'de-DE';

const calcValueFieldStartX = (doc) => {
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  return pageMargins.left + pageWidth * labelWidthPercent;
};

const calcSectionValueFieldStartX = (doc) => {
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  return pageMargins.left + pageWidth * (1 - labelWidthPercent);
};

const calcLabelWidth = (doc) => {
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  return pageWidth * labelWidthPercent;
};

const calcValueWidth = (doc) => {
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  return pageWidth * (1 - labelWidthPercent);
};

const getValueForField = (field, values, i18n) => {
  if (field.type === Constants.FIELD_TYPES.date) {
    // date
    return ReportHelper.getDateValueForField(values, field.name, i18n, '\n');
  }
  if (field.type === Constants.FIELD_TYPES.list) {
    // list
    return ReportHelper.getListValueForField(values, field.name, '\n');
  }
  const val = values[field.name];
  return val;
};

const createPageHeader = (doc) => {
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  doc.setFillColor('#000000');
  doc.rect(pageMargins.left, 15, pageWidth, 0.2, 'F');
  doc.text(doc.reportName, pageMargins.left, 13);
  doc.text(new Date().toLocaleString(locale), pageMargins.left + pageWidth, 13, { maxWidth: pageWidth, align: 'right' });
};

const pageWrap = (doc) => {
  doc.addPage();
  createPageHeader(doc);
  return pageMargins.top;
};

const calculateImageSize = async (base64content) => new Promise((resolve, reject) => {
  const pastedImage = new Image();
  pastedImage.onload = () => {
    try {
      resolve({ imgWidth: pastedImage.width, imgHeight: pastedImage.height });
    } catch (err) {
      reject(err);
    }
  };
  pastedImage.src = `data:image/jpg;base64,${base64content}`;
});

const addSection = (doc, currentPagePos, sectionTitle) => {
  let cpp = currentPagePos;
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
  const dim = doc.getTextDimensions('Öp');
  if (currentPagePos + defaultLineHeight + defaultSpacing > pageHeight) {
    cpp = pageWrap(doc);
  }
  doc.setFillColor(sectionTitleBackgroundColor);
  doc.rect(pageMargins.left, cpp, pageWidth, defaultLineHeight, 'F');
  doc.text(sectionTitle, pageMargins.left + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: pageWidth - 2 * fieldPadding });
  return cpp + defaultLineHeight + defaultSpacing;
};

const addSectionWithValue = (doc, currentPagePos, sectionTitle, value, backgroundColor, color) => {
  let cpp = currentPagePos;
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
  const dim = doc.getTextDimensions('Öp');
  if (currentPagePos + defaultLineHeight + defaultSpacing > pageHeight) {
    cpp = pageWrap(doc);
  }
  doc.setFillColor(sectionTitleBackgroundColor);
  doc.rect(pageMargins.left, cpp, calcValueWidth(doc), defaultLineHeight, 'F');
  doc.text(sectionTitle, pageMargins.left + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: calcValueWidth(doc) - 2 * fieldPadding });
  doc.setFillColor(backgroundColor);
  doc.rect(pageMargins.left + calcValueWidth(doc) + fieldPadding, cpp, calcLabelWidth(doc) - fieldPadding, defaultLineHeight, 'F');
  const font = doc.getFont();
  doc.setFont(font.fontName, 'normal', 700);
  doc.setTextColor(color);
  doc.text(
    value,
    pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
    cpp + fieldPadding + dim.h,
    { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
  );
  doc.setFont(font.fontName, 'normal', 'normal');
  doc.setTextColor('#000000');
  return cpp + defaultLineHeight + defaultSpacing;
};

const addLabel = (doc, currentPagePos, fieldConfig, language, fallbackLanguage) => {
  const dim = doc.getTextDimensions('Öp');
  let label = fieldConfig.title != null && fieldConfig.title[language] != null ? fieldConfig.title[language] : null;
  if (label == null) {
    label = fieldConfig.title != null && fieldConfig.title[fallbackLanguage] != null ? fieldConfig.title[fallbackLanguage] : fieldConfig.name;
  }
  doc.setFillColor(labelBackgroundColor);
  doc.rect(pageMargins.left, currentPagePos, calcLabelWidth(doc), defaultLineHeight, 'F');
  doc.text(label, pageMargins.left + fieldPadding, currentPagePos + fieldPadding + dim.h, { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding });
};

const createChunksFromValue = (doc, currentPagePos, value, maxWidth) => {
  let cpp = currentPagePos;
  const dim = doc.getTextDimensions('Öp');
  const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
  const lines = doc.splitTextToSize(value, maxWidth); // value.split('\n');
  // here we have to deal with values that are larger than 1 page -> we have to split the value into chunks that can be printd
  // on multiple pages!
  const valueChunks = [];
  let tmpCpp = cpp;
  let currentChunk = [];
  lines.forEach((l) => {
    if (tmpCpp + defaultLineHeight > pageHeight) {
      if (valueChunks.length === 0 && currentChunk.length === 0) {
        // the very first line leads to a page wrap -> do it!
        cpp = pageWrap(doc);
      }
      valueChunks.push(currentChunk);
      currentChunk = [];
      tmpCpp = pageMargins.top;
    }
    currentChunk.push(l);
    tmpCpp += dim.h * lineHeightFactor;
  });
  if (currentChunk.length > 0) valueChunks.push(currentChunk);
  return valueChunks;
};

const addLabelAndValue = (doc, currentPagePos, label, value, link = null) => {
  const dim = doc.getTextDimensions('Öp');
  const valueWidth = calcValueWidth(doc) - 2 * fieldPadding;
  let cpp = currentPagePos;
  if (value != null && value.length > 0) {
    const valueChunks = createChunksFromValue(doc, currentPagePos, value, calcValueWidth(doc) - 2 * fieldPadding);

    let chunkIdx = 0;
    valueChunks.forEach((currChunk) => {
      const chunkHeight = currChunk.length * dim.h * lineHeightFactor;
      doc.setFillColor(labelBackgroundColor);
      doc.rect(pageMargins.left, cpp, calcLabelWidth(doc), chunkHeight, 'F');
      doc.setFillColor(valueBackgroundColor);
      doc.rect(calcValueFieldStartX(doc), cpp, calcValueWidth(doc), chunkHeight, 'F');
      if (link) {
        doc.link(calcValueFieldStartX(doc), cpp, calcValueWidth(doc), chunkHeight, { url: link });
      }
      doc.text(label, pageMargins.left + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: calcLabelWidth - 2 * fieldPadding });
      doc.text(currChunk, calcValueFieldStartX(doc) + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: valueWidth });
      cpp += chunkHeight;
      chunkIdx += 1;
      if (chunkIdx < valueChunks.length) {
        cpp = pageWrap(doc);
      }
    });

    return cpp + defaultSpacing;
  }
  return cpp;
};

const addLabelAndSignature = async (doc, currentPagePos, label, value, link = null) => {
  const dim = doc.getTextDimensions('Öp');
  let cpp = currentPagePos;
  const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
  if (value != null && value.length > 0) {
    const { imgWidth, imgHeight } = await calculateImageSize(value);
    let scaleFactor = 1;
    if (imgWidth > imgHeight) {
      // landscape
      scaleFactor = maxImageSize / imgWidth;
    } else {
      // portrait
      scaleFactor = maxImageSize / imgHeight;
    }
    const resizedImageHeight = imgHeight * scaleFactor;
    const resizedImageWidth = imgWidth * scaleFactor;

    const rowHeight = resizedImageHeight + 2 * fieldPadding;
    if (cpp + rowHeight > pageHeight) {
      cpp = pageWrap(doc);
    }
    doc.setFillColor(labelBackgroundColor);
    doc.rect(pageMargins.left, cpp, calcLabelWidth(doc), rowHeight, 'F');
    doc.setFillColor(valueBackgroundColor);
    doc.rect(calcValueFieldStartX(doc), cpp, calcValueWidth(doc), rowHeight, 'F');
    doc.text(label, pageMargins.left + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: calcLabelWidth - 2 * fieldPadding });
    doc.addImage(`data:image/png;base64,${value}`, 'PNG', calcValueFieldStartX(doc) + fieldPadding, cpp + fieldPadding, resizedImageWidth, resizedImageHeight);
    return cpp + rowHeight + defaultSpacing;
  }
  return cpp;
};

const addTable = async (doc, currentPagePos, fieldConfig, values, i18n, language, fallbackLanguage) => {
  let tableConfig = { columns: [] };
  if (fieldConfig.special != null && fieldConfig.special.length > 5) tableConfig = JSON.parse(Buffer.from(fieldConfig.special, 'base64'));
  const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
  const tableWidth = calcValueWidth(doc) - 0.4;
  const dim = doc.getTextDimensions('Öp');
  const valueLeft = calcValueFieldStartX(doc) + fieldPadding;
  let leftPos = valueLeft;
  addLabel(doc, currentPagePos, fieldConfig, language, fallbackLanguage);
  let cpp = currentPagePos;
  // table head
  tableConfig.columns.forEach((col) => {
    const colWidth = ((col.width / 100) * tableWidth) - 0.2;
    doc.rect(leftPos, cpp, colWidth, defaultLineHeight);

    let textToRender = col.name;
    const maxWidth = colWidth - 2 * fieldPadding;
    while (doc.getTextDimensions(textToRender).w > maxWidth) {
      textToRender = textToRender.substring(0, textToRender.length - 1);
    }

    doc.text(textToRender, leftPos + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: colWidth - 2 * fieldPadding });
    leftPos += colWidth;
  });
  cpp += defaultLineHeight;

  // table body

  values.forEach((valueRow) => {
    // separate the row in multiple rows IF NECESSARY (which is, when a VERY long cell value is longer than one page...)
    const valueRows = [];
    const rowsChunks = [];
    let maxChunkCount = 0;
    tableConfig.columns.forEach((col) => {
      const value = valueRow[col.id];
      const colWidth = ((col.width / 100) * tableWidth) - 0.2;
      const valueWidth = colWidth - 2 * fieldPadding;
      const valueChunks = createChunksFromValue(doc, currentPagePos, value, valueWidth);
      rowsChunks.push(valueChunks);
      maxChunkCount = Math.max(valueChunks.length, maxChunkCount);
    });

    // fill empty cells for coumns with less chunks
    for (let i = 0; i < maxChunkCount; i += 1) {
      const tmpRow = {};
      for (let j = 0; j < rowsChunks.length; j += 1) {
        if (i < rowsChunks[j].length) tmpRow[tableConfig.columns[j].id] = rowsChunks[j][i] ? rowsChunks[j][i] : '';
        else tmpRow[tableConfig.columns[j].id] = '';
      }
      valueRows.push(tmpRow);
    }

    valueRows.forEach((row) => {
      leftPos = valueLeft;
      let maxLineHeight = defaultLineHeight;
      tableConfig.columns.forEach((col) => {
        const colWidth = ((col.width / 100) * tableWidth) - 0.2;
        const value = row[col.id];
        const valueWidth = colWidth - 2 * fieldPadding;
        const lines = doc.splitTextToSize(value, valueWidth); // value.split('\n');
        const valueHeight = lines.length * dim.h * lineHeightFactor; // defaultLineHeight * lines.length;
        maxLineHeight = Math.max(maxLineHeight, valueHeight);
      });

      if (cpp + maxLineHeight > pageHeight) {
        cpp = pageWrap(doc);
      }

      tableConfig.columns.forEach((col) => {
        const colWidth = ((col.width / 100) * tableWidth) - 0.2;
        const value = row[col.id];
        const valueWidth = colWidth - 2 * fieldPadding;

        doc.rect(leftPos, cpp, colWidth, maxLineHeight);
        doc.text(value != null ? value : '', leftPos + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: valueWidth });
        leftPos += colWidth;
      });
      cpp += maxLineHeight;
    });
  });

  return cpp + defaultSpacing;
};

const addField = async (doc, currentPagePos, fieldConfig, values, i18n, language, fallbackLanguage) => {
  let label = fieldConfig.title != null && fieldConfig.title[language] != null ? fieldConfig.title[language] : null;
  if (label == null) {
    label = fieldConfig.title != null && fieldConfig.title[fallbackLanguage] != null ? fieldConfig.title[fallbackLanguage] : fieldConfig.name;
  }

  if ([Constants.FIELD_TYPES.textfield, Constants.FIELD_TYPES.textarea, Constants.FIELD_TYPES.checkbox, Constants.FIELD_TYPES.list,
    Constants.FIELD_TYPES.dropdown, Constants.FIELD_TYPES.date].includes(fieldConfig.type)) {
    const value = getValueForField(fieldConfig, values, i18n);
    if (value != null && value.length > 0) {
      return addLabelAndValue(doc, currentPagePos, label, value);
    }
  } else if (fieldConfig.type === Constants.FIELD_TYPES.signature) {
    // signature
    const value = getValueForField(fieldConfig, values);
    if (value != null && value.length > 0) {
      const cpp = await addLabelAndSignature(doc, currentPagePos, label, value);
      return cpp;
    }
  } else if (fieldConfig.type === Constants.FIELD_TYPES.action) {
    // action
    // fieldConfig.type == 6 && getActionType(fieldConfig) == 5
    const value = ReportHelper.getGPSValueForField(fieldConfig, values, i18n);
    if (value != null && value.length > 0) {
      const cpp = await addLabelAndValue(doc, currentPagePos, label, value);
      return cpp;
    }
  } else if (fieldConfig.type === Constants.FIELD_TYPES.table) {
    const value = getValueForField(fieldConfig, values, i18n);
    if (value != null && value.length > 0) {
      const cpp = await addTable(doc, currentPagePos, fieldConfig, JSON.parse(value), i18n, language, fallbackLanguage);
      return cpp;
    }
  }
  return currentPagePos;
};

const addComment = (doc, currentPagePos, comment, i18n, language, fallbackLanguage) => {
  let cpp = currentPagePos;
  const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
  const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
  const lineCount = comment.content.split(/\r\n|\r|\n/).length;
  if (((comment.type === 'COMMENT' || comment.type === 'DELETE') && currentPagePos + commentHeaderLineHeight + lineCount * defaultLineHeight + defaultSpacing > pageHeight)
    || (comment.type !== 'COMMENT' && comment.type !== 'DELETE' && currentPagePos + defaultLineHeight + defaultSpacing > pageHeight)) {
    cpp = pageWrap(doc);
  }

  let dim = doc.getTextDimensions('Öp');

  // HEADER on normal or deleted comment
  if (comment.type === 'COMMENT' || comment.type === 'DELETE') {
    doc.setFontSize(commentHeaderFontSize);
    dim = doc.getTextDimensions('Öp');

    doc.setFillColor(labelBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, commentHeaderLineHeight, 'F');
    doc.text(comment.creatorName, pageMargins.left + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: calcValueWidth(doc) - 2 * fieldPadding });
    if (comment.type === 'DELETE') {
      doc.text(
        `[${i18n('detail.commentDeleted')}] ${new Date(comment.created).toLocaleString(locale)}`,
        pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
        cpp + fieldPadding + dim.h,
        { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
      );
    } else if (comment.type === 'COMMENT' && comment.created !== comment.modified) {
      doc.text(
        `[${i18n('detail.commentModified')}] ${new Date(comment.created).toLocaleString(locale)}`,
        pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
        cpp + fieldPadding + dim.h,
        { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
      );
    } else if (comment.type === 'COMMENT' && comment.created === comment.modified) {
      doc.text(
        new Date(comment.created).toLocaleString(locale),
        pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
        cpp + fieldPadding + dim.h,
        { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
      );
    }

    doc.setFontSize(fontSize);
    dim = doc.getTextDimensions('Öp');

    cpp += commentHeaderLineHeight;
  }

  if (comment.type === 'COMMENT') {
    doc.setFillColor(valueBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, lineCount * defaultLineHeight, 'F');
    doc.text(comment.content, pageMargins.left + fieldPadding, cpp + fieldPadding + dim.h, { maxWidth: pageWidth - 2 * fieldPadding });

    cpp += lineCount * defaultLineHeight;
  } else if (comment.type === 'DELETE' && comment.creator._id !== comment.modifier._id) {
    doc.setFillColor(valueBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, defaultLineHeight, 'F');
    doc.text(
      `[${i18n('detail.otherDelete')} ${comment.modifierName}]`,
      pageMargins.left + fieldPadding,
      cpp + (fieldPadding + dim.h),
      { maxWidth: pageWidth - 2 * fieldPadding },
    );

    cpp += defaultLineHeight;
  } else if (comment.type === 'DELETE' && comment.creator._id === comment.modifier._id) {
    doc.setFillColor(valueBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, defaultLineHeight, 'F');
    doc.text(
      `[${i18n('detail.selfDelete')}]`,
      pageMargins.left + fieldPadding,
      cpp + (fieldPadding + dim.h),
      { maxWidth: pageWidth - 2 * fieldPadding },
    );

    cpp += defaultLineHeight;
  } else if (comment.type === 'STATE') {
    doc.setFillColor(valueBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, defaultLineHeight, 'F');
    let text = comment.creatorName;
    text += ' ';
    text += i18n('detail.stateChangeBefore');
    text += ' ';
    text += i18n(`detail.state${comment.content.split('=>')[0]}`);
    text += ' ';
    text += i18n('detail.stateChangeTo');
    text += ' ';
    text += i18n(`detail.state${comment.content.split('=>')[1]}`);
    text += ' ';
    text += i18n('detail.stateChangeAfter');
    doc.text(
      text,
      pageMargins.left + fieldPadding,
      cpp + (fieldPadding + dim.h),
      { maxWidth: calcValueWidth(doc) - 2 * fieldPadding },
    );
    doc.text(
      new Date(comment.created).toLocaleString(locale),
      pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
      cpp + fieldPadding + dim.h,
      { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
    );

    cpp += defaultLineHeight;
  } else if (comment.type === 'EDIT' && comment.content === 'CREATED') {
    doc.setFillColor(valueBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, defaultLineHeight, 'F');
    let text = comment.creatorName;
    text += ' ';
    text += i18n('detail.createdContinuation');
    doc.text(
      text,
      pageMargins.left + fieldPadding,
      cpp + (fieldPadding + dim.h),
      { maxWidth: calcValueWidth(doc) - 2 * fieldPadding },
    );
    doc.text(
      new Date(comment.created).toLocaleString(locale),
      pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
      cpp + fieldPadding + dim.h,
      { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
    );

    cpp += defaultLineHeight;
  } else if (comment.type === 'EDIT' && comment.content === 'ALTERED') {
    doc.setFillColor(valueBackgroundColor);
    doc.rect(pageMargins.left, cpp, pageWidth, defaultLineHeight, 'F');
    let text = comment.creatorName;
    text += ' ';
    text += i18n('detail.alteredContinuation');
    doc.text(
      text,
      pageMargins.left + fieldPadding,
      cpp + (fieldPadding + dim.h),
      { maxWidth: calcValueWidth(doc) - 2 * fieldPadding },
    );
    doc.text(
      new Date(comment.created).toLocaleString(locale),
      pageMargins.left + calcValueWidth(doc) + calcLabelWidth(doc) - fieldPadding,
      cpp + fieldPadding + dim.h,
      { maxWidth: calcLabelWidth(doc) - 2 * fieldPadding, align: 'right' },
    );

    cpp += defaultLineHeight;
  }

  return cpp + defaultSpacing;
};

module.exports = {
  createPDF: async (values, pages, report, comments, i18n, language, fallbackLanguage) => {
    try {
      const pdfName = encodeURI(report.reportName);

      const doc = new PDF();
      doc.setLineHeightFactor(lineHeightFactor);
      doc.setFontSize(fontSize);
      doc.reportName = report.reportName;

      createPageHeader(doc);
      let currentPagePos = pageMargins.top;
      const pageWidth = doc.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
      const pageHeight = doc.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;

      currentPagePos = addSectionWithValue(doc, currentPagePos, i18n('detail.general'), i18n(`detail.state${report.state}`), stateBackgroundColors[report.state], stateColor);
      currentPagePos = addLabelAndValue(doc, currentPagePos, i18n('detail.name'), report.reportName);
      currentPagePos = addLabelAndValue(doc, currentPagePos, i18n('detail.created'), report.created);
      currentPagePos = addLabelAndValue(doc, currentPagePos, i18n('detail.creator'), report.creatorName);
      currentPagePos = addLabelAndValue(doc, currentPagePos, i18n('detail.modified'), report.modified);
      currentPagePos = addLabelAndValue(doc, currentPagePos, i18n('detail.modifier'), report.modifierName);
      currentPagePos += defaultLineHeight;

      let pageCnt = 1;
      await Promise.all(pages.map(async (page) => {
        currentPagePos = addSection(doc, currentPagePos, `${i18n('detail.page')} ${pageCnt}`);
        for (let idx = 0; idx < page.length; idx += 1) {
          // await Promise.all(page.map(async (field) => {
          const field = page[idx];
          // eslint-disable-next-line no-await-in-loop
          currentPagePos = await addField(doc, currentPagePos, field, values, i18n, language, fallbackLanguage);
        }
        pageCnt += 1;
      }));
      currentPagePos += defaultLineHeight;

      if (comments != null && comments.length > 0) {
        currentPagePos = addSection(doc, currentPagePos, i18n('detail.comments'));
        for (let i = 0; i < comments.length; i += 1) {
          currentPagePos = addComment(doc, currentPagePos, comments[i], i18n, language, fallbackLanguage);
        }
      }
      currentPagePos += defaultLineHeight;

      const url = new URL(window.location.href);
      // eslint-disable-next-line prefer-template
      const baseUrl = `${url.protocol}//${url.hostname}${(url.port != null && url.port.length > 0 ? ':' + url.port : '')}?attachmentId=`;

      if (report.attachments.photos != null && report.attachments.photos.length > 0) {
        currentPagePos = addSection(doc, currentPagePos, i18n('detail.photos'));
        const imageMargin = 4;
        let currentXPos = pageMargins.left;
        // let rowImageHeight = 0;
        await Promise.all(report.attachments.photos.map(async (p) => {
          const { imgWidth, imgHeight } = await calculateImageSize(p.content);
          let scaleFactor = 1;
          if (imgWidth > imgHeight) {
            // landscape
            scaleFactor = maxImageSize / imgWidth;
          } else {
            // portrait
            scaleFactor = maxImageSize / imgHeight;
          }

          if (currentPagePos + imgHeight * scaleFactor > pageHeight) {
            currentPagePos = pageWrap(doc);
          }
          doc.addImage(`data:image/jpg;base64,${p.content}`, 'JPEG', currentXPos, currentPagePos, imgWidth * scaleFactor, imgHeight * scaleFactor);
          doc.link(currentXPos, currentPagePos, imgWidth * scaleFactor, imgHeight * scaleFactor, { url: `${baseUrl}${p._id}` });

          currentXPos += imgWidth * scaleFactor + imageMargin;
          // put comment
          if (p.comment != null && p.comment.trim().length > 0) {
            doc.text(p.comment, currentXPos, currentPagePos + 4, { maxWidth: pageWidth - currentXPos });
          }

          // rowImageHeight = Math.max(imgHeight * scaleFactor, rowImageHeight);
          currentPagePos += imgHeight * scaleFactor + imageMargin;

          currentXPos = pageMargins.left;
        }));
        // currentPagePos += rowImageHeight;
      }

      if (report.attachments.voices != null && report.attachments.voices.length > 0) {
        let voiceIdx = 1;
        currentPagePos = addSection(doc, currentPagePos, i18n('detail.recordings'));
        report.attachments.voices.forEach((v) => {
          currentPagePos += addLabelAndValue(doc, currentPagePos, `${i18n('detail.recording')} ${voiceIdx}`, `${v.startTime}`, `${baseUrl}${v._id}`);
          voiceIdx += 1;
        });
      }

      currentPagePos += defaultLineHeight;

      doc.save(`${pdfName}.pdf`);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};
