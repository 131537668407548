<template>
  <div class="reportDetail">
    <div class="reportConfigs" v-if="!loading">
      <div class="page-title">{{ $t('pageTitle.reportDetail') }}
        <div class="actionBar">
          <div class="actionButton bgBrown refresh" @click="loadReportDetails"><i class="fas fa-sync"></i>
            <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
          </div>
          <div class="actionButton bgBrown refresh" @click="createPDF"><i class="fas fa-download"></i>
            <div class='actionButtonTitle'>PDF</div>
          </div>
          <!-- language selector start -->
          <div class="languageSelector actionButton bgTurquoise" :class="{'open': activeLanguageSwitching}" @click='activeLanguageSwitching = !activeLanguageSwitching'>
            <div class="languageSelectorSelection" v-if="activeLanguageSwitching">
              <div class="languageSelectorButton"
              :class='{"selected": config != null && activeLanguageDisplay == config.languageFallback}'
              @click.stop="activeLanguageDisplay = config.languageFallback; activeLanguageSwitching = !activeLanguageSwitching;">
                {{ $t('editor.fallbackLanguage') }} ({{ config.languageFallback.toUpperCase() }})
              </div>
              <div v-for="(language, idx) in config.languages" :key="idx" class="languageSelectorButtonHull"
              @click.stop="activeLanguageDisplay = language; activeLanguageSwitching = !activeLanguageSwitching;">
                <div v-if="language != config.languageFallback" class="languageSelectorButton"
                :class='{"selected": activeLanguageDisplay == language}'>
                  {{ language.toUpperCase() }}
                </div>
              </div>
            </div>
            <i class="fas fa-language"></i>
            <div class='actionButtonTitle'>{{ $t('editor.language') }}
              <div class="languageSelectorSelected" v-if="!activeLanguageSwitching">
                {{ config != null && activeLanguageDisplay != config.languageFallback ? (' ('+activeLanguageDisplay.toUpperCase()+')') : '' }}
              </div>
            </div>
          </div>
          <!-- language selector end -->
        </div>
      </div>
      <div class="currentLocation" v-if='details != null'>
        <div class='backButtonWrapper'>
          <div class='navButton' @click="$router.go(-1)"><i class="fas fa-arrow-left"/> <div class='navButtonTitle'>{{ $t('general.back') }}</div></div>
        </div>
        <!-- state widget start -->
        <div class="selectorHull">
          <div class="stateSelector" :class="{ 'new': report.state == 0, 'in-progress': report.state == 1, 'done': report.state == 2 }"
            @click='activeStateSwitching = !activeStateSwitching'>
            <div class="stateSelectorSelection" v-if="activeStateSwitching">
              <div v-for="(state) in [0, 1, 2]" :key="state" class="stateSelectorButtonHull"
              @click.stop="changeState(state); activeStateSwitching = !activeStateSwitching;">
                <div class="stateSelectorButton"
                :class="{ 'new': state == 0, 'in-progress': state == 1, 'done': state == 2, 'selected': state == report.state }">
                  {{ $t('detail.state' + state) }}
                </div>
              </div>
            </div>
            <i class="fas fa-sign"/> <span class='actionTitle'>{{ $t('detail.state' + report.state) }}</span>
          </div>
        </div>
        <!-- state widget end -->

        <div class="currentLocationTitle">
          {{reportConfigName}} / {{reportName}}
        </div>
      </div>
      <div v-if="details != null" class="screen-container value-list" ref="screenContainer" :style='{ height: screenContainerHeight }'>

        <div class="section">
          <div class="section-title">{{ $t('detail.general') }}</div>
          <div class="value-row value-row-content">
            <div class="label">{{ $t('detail.name') }}</div>
            <div class="value">{{reportName}}</div>
          </div>
          <div class="value-row value-row-content">
            <div class="label">{{ $t('detail.created') }}</div>
            <div class="value">{{new Date(report.created).toLocaleString()}}</div>
          </div>
          <div class="value-row value-row-content">
            <div class="label">{{ $t('detail.creator') }}</div>
            <div class="value">{{report.creatorName}}</div>
          </div>
          <div class="value-row value-row-content">
            <div class="label">{{ $t('detail.modified') }}</div>
            <div class="value">{{new Date(report.modified).toLocaleString()}}</div>
          </div>
          <div class="value-row value-row-content">
            <div class="label">{{ $t('detail.modifier') }}</div>
            <div class="value">{{report.modifierName}}</div>
          </div>
        </div>

        <div v-for="(page, idx) in pagesArray" :key="idx">
          <div class="section">
            <div class="section-title">{{pagesArray.length ==1 ? $t('detail.values') : `${$t('detail.page')} ` + (idx + 1)}}</div>
            <div class="value-row" v-for="fieldConfig in page" :key="'value_' + fieldConfig.name">
              <div class="value-row-content" v-if="[FIELD_TYPES.textfield, FIELD_TYPES.textarea, FIELD_TYPES.dropdown].includes(fieldConfig.type)">
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value">{{getValueForField(fieldConfig)}}</div>
              </div>
              <div class="value-row-content checkbox" v-if="fieldConfig.type == FIELD_TYPES.checkbox"> <!-- checkbox -->
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value" v-if="getValueForField(fieldConfig) === '0'"><i class="far fa-square"/></div>
                <div class="value" v-if="getValueForField(fieldConfig) === '1'"><i class="far fa-check-square"/></div>
              </div>
              <div class="value-row-content" v-if="fieldConfig.type == FIELD_TYPES.signature && getValueForField(fieldConfig) != null"> <!-- signature -->
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value">
                  <img class="signature" :src="'data:image/png;base64,' + getValueForField(fieldConfig)"/>
                </div>
              </div>
              <div class="value-row-content" v-if="fieldConfig.type == FIELD_TYPES.date && getDateValueForField(fieldConfig) != null"> <!-- date -->
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value" v-html="getDateValueForField(fieldConfig)"></div>
              </div>

              <div class="value-row-content" v-if="fieldConfig.type == FIELD_TYPES.list && getListValueForField(fieldConfig).length > 0"> <!-- list -->
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value" v-html="getListValueForField(fieldConfig)"></div>
              </div>

              <div class="value-row-content" v-if="fieldConfig.type == FIELD_TYPES.table && getTableValueForField(fieldConfig).length > 0"> <!-- list -->
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value" v-html="getTableValueForField(fieldConfig)"></div>
              </div>

              <div class="value-row-content" v-if="fieldConfig.type == FIELD_TYPES.action
                && getActionType(fieldConfig) == ACTION_TYPES.locatePosition
                && getGPSValueForField(fieldConfig) != null"> <!-- GPS location -->
                <div class="label">
                  {{fieldConfig.title[activeLanguageDisplay] != null ? fieldConfig.title[activeLanguageDisplay] : fieldConfig.title[config.languageFallback]}}{{fieldConfig.isMandatory ? '*' : ''}}
                </div>
                <div class="value">{{getGPSValueForField(fieldConfig)}}<br/><a class="mapLink" target="_osm" :href="getMapLink(fieldConfig)">OpenStreetMap</a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="section" v-if="report.attachments != null && report.attachments.photos != null &&  report.attachments.photos.length > 0">
          <div class="section-title">{{ $t('detail.photos') }}</div>
          <div class="value-row value-row-padded">
            <div v-for="photo in report.attachments.photos" :key="'photo_'+photo._id" class="imgDiv"  @click="downloadPhoto(photo)">
              <img class="photo" :src="'data:image/jpg;base64,' + photo.content"/>
              <div v-if="photo.comment != null && photo.comment.trim().length > 0" class="comment">{{photo.comment}}</div>
            </div>
          </div>
        </div>

        <div class="section" v-if="report.attachments != null && report.attachments.voices != null && report.attachments.voices.length > 0">
          <div class="section-title">{{ $t('detail.recordings') }}</div>
          <div class="value-row value-row-padded" v-for="rec in report.attachments.voices" :key="'rec_'+rec._id">
            <div class="value-row-content">
              <div class="label">{{rec.startTime}}</div>
              <div class="value">
                <audio controls>
                  <source :src="'data:audio/wav;base64,' + rec.content">
                    Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
        </div>

        <br>

        <div class="section">
          <div class="section-title">{{ $t('detail.comments') }}</div>
          <div class="add-comment">
            <textarea v-model="comment" ref="commentInput" @input="adjustCommentInput()" style="overflow:hidden" :placeholder="[[ $t('detail.placeholderComment') ]]"></textarea>
            <div class="navButton" @click="addComment(); adjustCommentInput()"><i class="fas fa-comment-alt"></i> <span class="commentButtonTitle">{{ $t('detail.saveComment') }}</span></div>
          </div>
          <div class="value-row value-row-padded comment" v-for="comment, id in comments" :key="id">

            <div class="comment-user" v-if="comment.type == 'COMMENT'">{{ comment.creatorName }}</div>
            <div class="comment-date" v-if="comment.type == 'COMMENT'">{{ comment.created != comment.modified ? '[' + $t('detail.commentModified') + ']' : '' }}
              {{ (new Date(comment.created)).toLocaleString() }}</div>
            <div class="comment-text" style="white-space: pre-wrap;" v-if="comment.type == 'COMMENT' && commentEditing != id">{{ comment.content }}</div>
            <textarea class="comment-text-edit" v-if="comment.type == 'COMMENT' && commentEditing == id" v-model="commentEdit"
              @input="textAreaAdjust($event)" style="overflow:hidden" :placeholder="[[ comment.content ]]"></textarea>
            <div class="comment-controls" v-if="comment.type == 'COMMENT'">
              <span v-if="commentEditing != id && $root.user._id == comment.creator._id"
                class="comment-control" @click="commentEditing = id; commentEdit = comment.content;" :title="$t('action.edit')"><i class="fas fa-pen"/></span>
              <span v-if="commentEditing != id && ($root.user._id == comment.creator._id || $root.user.isAdmin)"
                class="comment-control" @click="deleteComment(comment._id)" :title="$t('action.delete')"><i class="fas fa-trash"/></span>
              <div v-if="commentEditing == id" class="comment-control comment-edit"
                @click="commentEditing = ''; commentEdit = '';"><i class="fas fa-times"></i> <span>{{ $t('action.cancel') }}</span></div>
              <div v-if="commentEditing == id" class="comment-control comment-edit"
                @click="editComment(comment._id)"><i class="fas fa-comment-alt"></i> <span>{{ $t('detail.editComment') }}</span></div>
            </div>

            <div class="comment-user" v-if="comment.type == 'DELETE'">{{ comment.creatorName }}</div>
            <div class="comment-date" v-if="comment.type == 'DELETE'">{{ '[' + $t('detail.commentDeleted') + ']' }}
              {{ (new Date(comment.created)).toLocaleString() }}</div>
            <div class="comment-text" v-if="comment.type == 'DELETE' && comment.creator._id == comment.modifier._id">
              [{{ $t('detail.selfDelete') }}]
            </div>
            <div class="comment-text" v-if="comment.type == 'DELETE' && comment.creator._id != comment.modifier._id">
              [{{ $t('detail.otherDelete') }}&nbsp;<span class="comment-state-user">{{ comment.modifierName }}</span> ]
            </div>

            <div class="comment-state-row" v-if="comment.type == 'STATE'">
              <div class="comment-state-content">
                <span class="comment-state-user">{{ comment.creatorName }}</span>&nbsp;{{ $t('detail.stateChangeBefore') }}
                <span :class="{ 'state-new': comment.content.split('=>')[0] == '0', 'state-in-progress': comment.content.split('=>')[0] == '1', 'state-done': comment.content.split('=>')[0] == '2' }"
                  >{{ $t('detail.state' + comment.content.split('=>')[0]) }}</span>
                {{ $t('detail.stateChangeTo') }}
                <span :class="{ 'state-new': comment.content.split('=>')[1] == '0', 'state-in-progress': comment.content.split('=>')[1] == '1', 'state-done': comment.content.split('=>')[1] == '2' }"
                  >{{ $t('detail.state' + comment.content.split('=>')[1]) }}</span>
                {{ $t('detail.stateChangeAfter') }}
              </div>
              <div class="comment-state-date">{{ (new Date(comment.created)).toLocaleString() }}</div>
            </div>

            <div class="comment-state-row" v-if="comment.type == 'EDIT' && comment.content =='ALTERED'">
              <div class="comment-state-content"><span class="comment-state-user">{{ comment.creatorName }}</span>&nbsp;{{ $t('detail.alteredContinuation') }}</div>
              <div class="comment-state-date">{{ (new Date(comment.created)).toLocaleString() }}</div>
            </div>
            <div class="comment-state-row" v-if="comment.type == 'EDIT' && comment.content =='CREATED'">
              <div class="comment-state-content"><span class="comment-state-user">{{ comment.creatorName }}</span>&nbsp;{{ $t('detail.createdContinuation') }}</div>
              <div class="comment-state-date">{{ (new Date(comment.created)).toLocaleString() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-else>
      <LoadingSpinnerIndicatorComponent />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */

import Constants from '@/helpers/Constants';
import CryptoHelper from '@/helpers/CryptoHelper';
import ReportHelper from '@/helpers/ReportHelper';
import ServiceHelper from '@/helpers/ServiceHelper';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import PDFHelper from '@/helpers/PdfHelper';
import LanguageHelper from '@/helpers/LanguageHelper';
import LoadingSpinnerIndicatorComponent from './LoadingSpinnerIndicatorComponent.vue';

export default {
  name: 'ReportListView',
  props: ['reportId', 'reportName', 'reportConfigId', 'reportConfigName'],
  components: {
    LoadingSpinnerIndicatorComponent,
  },
  mixins: [ScreenHeightMixin],
  data() {
    return {
      loading: false,
      details: null,
      report: null,
      comment: '',
      commentEdit: '',
      commentEditing: '',
      comments: null,
      config: null,
      activeStateSwitching: false,
      values: {},
      pagesDict: {},
      pagesArray: [],
      FIELD_TYPES: Constants.FIELD_TYPES,
      ACTION_TYPES: Constants.ACTION_TYPES,
      baseUrlForAttachments: null,
      activeLanguageDisplay: LanguageHelper.getBrowserLocale(),
      activeLanguageSwitching: false,
    };
  },
  mounted() {
    this.loadReportDetails();
    const url = new URL(window.location.href);
    const port = url.port != null && url.port.length > 0 ? `:${url.port}` : '';
    this.baseUrlForAttachments = `${url.protocol}//${url.hostname}${port}?attachmentId=`;
  },
  methods: {
    getUserName(user) {
      let name = '';
      if (user.firstname != null) name += CryptoHelper.decrypt(user.firstname);
      name += ' ';
      if (user.lastname != null) name += CryptoHelper.decrypt(user.lastname);
      return name.trim();
    },

    async loadReportDetails() {
      this.loading = true;
      try {
        const configData = await ServiceHelper.loadDataFromBackend(`reportConfig/${this.reportConfigId}`);
        // check for error response
        this.config = JSON.parse(CryptoHelper.decrypt(configData.payload.content));
        // TODO: add language menu?!
        this.activeLanguageDisplay = this.config != null ? this.config.languageFallback : LanguageHelper.getBrowserLocale();
        const detailData = await ServiceHelper.loadDataFromBackend(`report/${this.reportId}`);
        this.report = detailData.payload;

        this.report.reportName = CryptoHelper.decrypt(this.report.name);
        this.report.modifierName = this.getUserName(this.report.modifier);
        this.report.creatorName = this.getUserName(this.report.creator);
        if (this.report.state == null) {
          this.report.state = 0;
        }

        this.details = JSON.parse(CryptoHelper.decrypt(detailData.payload.content));
        // console.log('content', JSON.stringify(this.details));
        // free some memory
        delete this.report.content;
        this.details.attachments = {};
        this.details.assigned_values.forEach((v) => {
          this.values[v.valueKey] = v.value;
        });
        this.pagesArray = [];
        this.pagesDict = {};

        // organize fields in pages
        this.config.fields.forEach((field) => {
          const pageIdx = field.page !== -1 ? field.page : 0;
          const page = this.pagesDict !== null && this.pagesDict[`${pageIdx}`] != null ? this.pagesDict[`${pageIdx}`] : [];
          this.pagesDict[`${pageIdx}`] = page;
          page.push(field);
        });
        Object.keys(this.pagesDict).sort().forEach((p) => {
          this.pagesArray.push(this.pagesDict[p]);
        });

        if (detailData.payload.attachments != null) {
          this.report.attachments.photos = await Promise.all(detailData.payload.attachments.photos.map(async (p) => {
            const dec = CryptoHelper.decrypt(p.content);
            const obj = JSON.parse(dec);
            obj._id = p._id;
            if (p.comment != null) {
              obj.comment = CryptoHelper.decrypt(p.comment);
            }
            return obj;
          }));
          this.report.attachments.voices = await Promise.all(detailData.payload.attachments.voices.map(async (p) => {
            const dec = CryptoHelper.decrypt(p.content);
            const obj = JSON.parse(dec);
            obj._id = p._id;
            return obj;
          }));
        }

        this.digestComments(detailData.payload.comments);
        delete this.report.comments;
        // console.log('report', JSON.stringify(this.report));
      } catch (error) {
        console.error(error);
        this.details = null;
      } finally {
        this.loading = false;
        this.resizeHandler();
      }
    },
    getValueForField(fieldConfig) {
      const val = this.values[fieldConfig.name];
      return val;
    },
    getDateValueForField(fieldConfig) {
      return ReportHelper.getDateValueForField(this.values, fieldConfig.name, this.$t, '<br/>');
    },
    getListValueForField(fieldConfig) {
      return ReportHelper.getListValueForField(this.values, fieldConfig.name, '<br/>');
    },
    getTableValueForField(fieldConfig) {
      if (fieldConfig.special == null || fieldConfig.special.length < 4) return '';
      const tableConfig = JSON.parse(Buffer.from(fieldConfig.special, 'base64').toString());
      return ReportHelper.getTableValueForField(this.values, fieldConfig.name, tableConfig);
    },
    async createPDF() {
      await PDFHelper.createPDF(this.values, this.pagesArray, this.report, this.comments, this.$t, this.activeLanguageDisplay, this.config.fallbackLanguage);
    },
    getGPSValueForField(fieldConfig) {
      return ReportHelper.getGPSValueForField(fieldConfig, this.values, this.$t);
    },
    getMapLink(fieldConfig) {
      return ReportHelper.getMapLink(fieldConfig, this.values);
    },
    getActionType(fieldConfig) {
      return ReportHelper.getActionType(fieldConfig);
    },
    downloadPhoto(photo) {
      const type = 'image/jpeg';
      const blob = new Blob([Buffer.from(photo.content, 'base64')], { type });
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', photo._id);
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file
      link.remove();
    },
    adjustCommentInput() {
      if (this.$refs.commentInput) {
        const element = this.$refs.commentInput;
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
      }
    },
    async digestComments(comments) {
      if (comments != null) {
        this.comments = [];
        for (let i = 0; i < comments.length; i += 1) {
          const comment = comments[i];
          if (comment.type === 'COMMENT') {
            comment.content = CryptoHelper.decrypt(comment.content);
          }
          comment.creatorName = this.getUserName(comment.creator);
          comment.modifierName = this.getUserName(comment.modifier);
          this.comments.push(comment);
        }
      }
    },
    async addComment() {
      if (this.comment != null && this.comment !== '') {
        const payload = await ServiceHelper.sendDataToBackend(`report/${this.reportId}/comment`, { comment: CryptoHelper.encrypt(this.comment) });
        this.digestComments(payload.payload.comments);
        this.comment = '';
      }
    },
    async deleteComment(id) {
      try {
        const payload = await ServiceHelper.deleteDataFromBackend(`report/${this.reportId}/comment/${id}`);
        this.digestComments(payload.payload.comments);
      } catch (error) {
        console.error(error);
      }
    },
    async editComment(id) {
      try {
        if (this.commentEdit != null && this.commentEdit !== '') {
          const payload = await ServiceHelper.sendDataToBackend(`report/${this.reportId}/comment/${id}`, { comment: CryptoHelper.encrypt(this.commentEdit) }, 'PUT');
          this.digestComments(payload.payload.comments);
          this.commentEditing = '';
          this.commentEdit = '';
        }
      } catch (error) {
        console.error(error);
      }
    },
    async changeState(state) {
      await ServiceHelper.sendDataToBackend(`report/${this.reportId}/state`, { state });
      try {
        const detailData = await ServiceHelper.loadDataFromBackend(`report/${this.reportId}`);
        if (detailData.payload.state == null) {
          this.report.state = 0;
        } else {
          this.report.state = detailData.payload.state;
        }

        this.digestComments(detailData.payload.comments);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.reportDetail {
  position: relative;
}

.value-list {
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
}

.value-list .value-row-content {
  line-height: 28px;
  display: flex;
  justify-items: auto;
}

.section .value-row:nth-child(odd) {
  background-color: white;
}

.section .value-row:nth-child(even) {
  background-color: var(--color_almost_white);
}

.value-list .value-row .label {
  flex: 1;
  padding: 12px;
  font-weight: 600;
}

.value-list .value-row .value {
  flex: 2;
  padding: 12px;
  text-align: left;
}

.value-list .value-row .signature {
  max-height: 600px;
  max-width: 600px;
}

.value-list .value-row-content.checkbox .value {
  font-size: 2em;
}

.value-row-padded {
  padding: 12px;
}

table.customTable, ::v-deep(table.customTable) {
  width: 100%;
  border: 1px solid var(--color_light_gray);
}

table.customTable td, ::v-deep(table.customTable td) {
  padding: 0 4px;
}

table.customTable th, ::v-deep(table.customTable th) {
  border-collapse: collapse;
  background-color: var(--color_orange_half);
}

table.customTable tr:nth-child(even) td, ::v-deep(table.customTable tr:nth-child(even) td) {
  background-color: var(--color_light_gray);
}

.section-title {
  background-color: var(--color_orange);
  font-size: 24px;
  padding: 12px;
  color: white;
}

.mapLink, .mapLink:visited, .mapLink:active {
  color: var(--color_orange);
  text-decoration: none;
}

.mapLink:hover {
  font-weight: 800;
}

.imgDiv {
  margin-bottom: 16px;
  background-color: var(--color_orange)22;
  cursor: pointer;
}

.imgDiv img {
  display: inline-block;
}

.imgDiv .comment {
  display: inline-block;
  vertical-align: top;
}

.imgDiv img.photo {
  margin-right: 12px;
  max-width: 400px;
  max-height: 400px;
  vertical-align: middle;
}

.add-comment {
  display: grid;
  grid-template-columns: 1fr auto;
}
.add-comment > textarea {
  grid-column: 1;
  resize: none;
  margin: 5px;
}
.add-comment > div {
  background-color: var(--color_brown);
  padding: 8px 12px;
  color: white;
  font-size: 19px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  grid-column: 2;
  margin: 5px 12px 5px 0;
  right: 0;
  height: fit-content;
}
.add-comment > div:hover {
  background-color: var(--color_almost_black);
}

.comment {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
}

.comment-user {
  font-size: 14px;
  font-weight: bold;
  grid-row: 1;
  grid-column: 1;
}
.comment-date {
  font-size: 14px;
  font-weight: bold;
  grid-row: 1;
  grid-column: 2;
  margin-left: auto;
  right: 0;
}
.comment-controls {
  grid-row: 2;
  grid-column: 2;
  right: 0;
  margin-left: auto;
}
.comment-control {
  display: inline-block;
  margin: 0 5px 0 0;
  cursor: pointer;
}

.comment-control.comment-edit {
  background-color: var(--color_brown);
  padding: 8px 12px;
  color: white;
  font-size: 19px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px 0 5px 5px;
  right: 0;
  height: fit-content;
}
.comment-control.comment-edit:hover {
  background-color: var(--color_almost_black);
}

.comment-text {
  grid-row: 2;
  grid-column: 1;
  padding: 0 0 0 5px;
  border-left: 3px solid var(--color_brown);
}
.comment-text-edit {
  resize: none;
  margin: 5px 0 5px 5px;
  grid-row: 2;
  grid-column: 1;
}

.comment-state-row {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row: 1 / span 3;
  grid-column: 1 / span 2;
}
.comment-state-content {
  grid-column: 1;
}
.comment-state-user {
  display: inline-block;
  border: 1px solid var(--color_brown);
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  padding: 0 3px;
}
.comment-state-date {
  font-size: 14px;
  font-weight: bold;
  grid-column: 2;
  margin-left: auto;
  right: 0;
}
.state-new {
  display: inline-block;
  border: 1px solid var(--state-new-color);
  color: var(--state-new-color);
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  padding: 0 3px;
}
.state-in-progress {
  display: inline-block;
  border: 1px solid var(--state-in-progress-color);
  color: var(--state-in-progress-color);
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  padding: 0 3px;
}
.state-done {
  display: inline-block;
  border: 1px solid var(--state-done-color);
  color: var(--state-done-color);
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  padding: 0 3px;
}

.selectorHull {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 99;
}

.stateSelector {
  display: inline-block;
  color: black;
  cursor: pointer;
  padding: 6px 15px;
  background-color: var(--color_almost_white);
  border: 2px solid black;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}
.stateSelector.new {
  color: var(--state-new-color);
  border-color: var(--state-new-color);
}
.stateSelector.in-progress {
  color: var(--state-in-progress-color);
  border-color: var(--state-in-progress-color);
}
.stateSelector.done {
  color: var(--state-done-color);
  border-color: var(--state-done-color);
}
.stateSelectorSelection {
  margin: -1px 0px -1px -10px;
  display: inline-block;
}
.stateSelectorSelected {
  display: inline-block;
}
.stateSelectorButtonHull {
  display: inline-block;
}
.stateSelectorButton {
  display: inline-block;
  padding: 0px 10px;
  margin: 0px 5px 0px 0px;
  border: 2px solid black;
  border-radius: 15px;
  color: black;
  font-size: 15px;
  font-weight: bold;
  line-height: 28px;
}
.stateSelectorButton.new {
  color: var(--state-new-color);
  border-color: var(--state-new-color);
}
.stateSelectorButton.in-progress {
  color: var(--state-in-progress-color);
  border-color: var(--state-in-progress-color);
}
.stateSelectorButton.done {
  color: var(--state-done-color);
  border-color: var(--state-done-color);
}
.stateSelectorButton.selected {
  color: var(--color_almost_white);
}
.stateSelectorButton.selected.new {
  background-color: var(--state-new-color);
}
.stateSelectorButton.selected.in-progress {
  background-color: var(--state-in-progress-color);
}
.stateSelectorButton.selected.done {
  background-color: var(--state-done-color);
}

.languageSelector.open {
  padding: 8.5px 12px;
}
.languageSelector.open:hover {
  background: var(--color_mint);
}

.languageSelectorSelection {
  display: inline-block;
}
.languageSelectorSelected {
  display: inline-block;
}
.languageSelectorButtonHull {
  display: inline-block;
}
.languageSelectorButton {
  display: inline-block;
  padding: 0 10px;
  margin: 0 5px 0 0;
  border-radius: 15px;
  border: 2px solid var(--color_brown);
  color: var(--color_brown);
  font-size: 15px;
  font-weight: bold;
  line-height: 28px;
}
.open .languageSelectorButton:hover {
  background: var(--color_brown);
  color: white;
}

.languageSelectorButton.selected {
  background-color: var(--color_brown);
  color: var(--color_almost_white);
}

.backButtonWrapper {
  display: inline-block;
}

@media (max-width: 800px) {
  .open .languageSelectorButton {
    margin-bottom: 4px;
  }

  .stateSelectorButton {
    margin-bottom: 8px;
  }

  .commentButtonTitle {
    display: none;
  }

  .value-list .value-row-content {
    line-height: 28px;
    display: flex;
    justify-items: auto;
    flex-direction: column;
  }

  .value-list .value-row .label {
    font-weight: 600;
    flex: 1;
    padding: 12px 12px 0px 12px;
  }

  .value-list .value-row .value {
    flex: 1;
    padding: 0px 12px 12px 12px;
    text-align: left;
  }
}

</style>
